<template>
<form @submit.prevent="">


    <div v-if="isMobile" class="form-row">
        <v-text-field class="mobile-app-input mobile-app-input-modal" label="عنوان ویجت" id="title" v-model="data.title"></v-text-field>
    </div>
    
    <div v-if="isMobile" class="form-row">
        <v-select  v-model="data.category_id" class="profile-mobile-view-select mobile-view-select-modal fit-h" :items="categories" item-text="title"  item-value="id" label="انتخاب دسته" outlined></v-select>
    </div>

    <div class="form-row" v-if="!isMobile">
        <div class="col-12">
            <input type="text" id="title" placeholder="عنوان ویجت" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="data.title">
        </div>
    </div>

    <div class="form-row" v-if="!isMobile">
        <div class="col-12">
            <div class="widget-select w-100 m-top widget-select-lg">
                <v-select2 v-model="data.category_id" :clearable="false" label="title" :reduce="(option) => option.id" :options="categories">
                </v-select2>
            </div>
        </div>
    </div>
    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveSettings()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary"><i class="uil uil-save"></i> ذخیره</a>
        </div>
    </div>
</form>
</template>

<script>
// این کامپوننت مربوط به محتوای ویجت های تنظیمات (اخبار) است
import vSelect2 from 'vue-select'
Vue.component('v-select2', vSelect2);

export default {
    name: 'SettingsNews',
    components: {},
    props: ['data'],
    data: function () {
        return {
            categories: [{
                id: 93964,
                title: 'اخبار طلا و سکه'
            }, {
                id: 93965,
                title: 'اخبار بازار ارز'
            }, {
                id: 93966,
                title: 'ارزهای دیجیتال'
            }, {
                id: 93967,
                title: 'اخبار نفت و انرژی'
            }, {
                id: 93968,
                title: 'اخبار بورس'
            }, {
                id: 83979,
                title: 'اخبار مسکن و عمران'
            }, {
                id: 83980,
                title: 'اخبار خودرو'
            }, {
                id: 93969,
                title: 'اخبار بانک و بیمه'
            }, {
                id: 83986,
                title: 'اخبار تولید'
            }, {
                id: 83986,
                title: 'اخبار تجارت'
            }, {
                id: 83981,
                title: 'اخبار قیمت‌ها'
            }, {
                id: 83982,
                title: 'اخبار اقتصادی'
            }, {
                id: 83983,
                title: 'اخبار جهان'
            }, {
                id: 83984,
                title: 'اخبار فناوری'
            },{
                id: 120,
                title: 'اخبار ویژه'
            }],
            isMobile:false,
        }
    },
    methods: {
        // این متد جهت ارسال اطلاعات داخل مودال به کلاس یا واسط پرنت می باشد(همون کال باک های معروف)
        saveSettings() {
            let data = this.$helpers.unbindObject(this.data);
            this.$parent.saveSettings(data);
        },
    },
    mounted() {
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
}
</script>
